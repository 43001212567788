<template>
  <div class="row justify-content-center">
    <div class="col-10 col-lg-6">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">{{ $t('po_re_he_reg') }}</h5>
          <form id="Login" @submit.prevent="register">
            <user-form v-model="user"></user-form>
            <div class="d-grid">
              <button type="submit" class="col btn btn-primary mt-3">
                {{ $t('po_re_bt_reg') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import userForm from '@/components/user/UserForm.vue'

export default {
  components: {
    userForm
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  data: function () {
    return {
      user: {}
    }
  },
  methods: {
    async register () {
      if (await this.v$.$validate()) {
        this.user.birthdate = '1990-01-01'
        this.axios.post('/register/', this.user).then((res) => {
          this.$router.push({ name: 'registered' })
        }).catch(() => {
          this.registerError = true
          this.registerDisabled = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
