<template>
  <div class="d-flex justify-content-between">
    <div>
        <h5><font-awesome-icon icon="circle" :class="[restaurant.open ? 'text-success': 'text-danger']" size="xs" v-if="restaurant.open !== undefined"/> <router-link :to="{ name: 'restaurant', params: { id: restaurant.id } }">{{mainText.name}}</router-link></h5>
        <p>{{mainText.description}}</p>
    </div>
    <div v-if="!hideRoles">
      <img v-if="isWaiter" alt="Kellner" src="../assets/images/icons/waiter.svg" height="30" class="m-1">
      <img v-if="isManager" alt="Manager" src="../assets/images/icons/manager.svg" height="30" class="m-1">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    restaurant: Object,
    hideRoles: Boolean
  },
  data: function () {
    return {
      isWaiter: this.$auth.check({ [this.restaurant.id]: ['waiter'] }),
      isManager: this.$auth.check({ [this.restaurant.id]: ['admin'] }),
      mainText: this.restaurant.texts.find((text) => text.language === this.restaurant.language)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
