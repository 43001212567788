<template>
  <div class="container">
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-3">
        <h1>404</h1>
        <p class="lead">Die Seite konnte leider nicht gefunden werden.</p>
        <p class="lead">
          <router-link to="/" class="btn btn-primary" role="button">Zur Startseite</router-link>
        </p>
      </div>
    </div>
  </div>
</template>
