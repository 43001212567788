<template>
  <div>
    <form id="login" @submit.prevent="invite">
      <div class="input-group">
        <input id="email" name="email" type="email" class="form-control" :class="{ 'is-invalid': v$.email.$error }" :placeholder="$t('po_or_lb_email')" v-model="email">
        <div class="input-group-append">
          <button type="submit" class="btn btn-primary btn-block">{{ $t('po_or_bt_invite') }}</button>
        </div>
        <div class="invalid-feedback w-100">{{ v$.email.$errors[0]?.$message }}</div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/config/i18n-validators'

export default {
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  },
  data () {
    return {
      responseStatus: 0,
      loading: false,
      email: ''
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    }
  },
  methods: {
    hasRole: function (user, role) {
      return user.organisationRoles.some((organisationRole) => organisationRole.role === role)
    },
    invite: async function () {
      this.resetErrors()

      if (await this.v$.$validate()) {
        this.axios.post(`/organisations/${this.organisation.uuid}/users/invite`, { email: this.email }).then(res => {
          this.responseStatus = res.status
        }).catch((e) => {
          this.responseStatus = e.response.status
        })
      }
    },
    resetErrors: function () {
      this.loginResponseStatus = 0
      this.registerError = false
    }
  }
}
</script>
