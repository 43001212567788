<template>
  <div id="app">
    <navigation></navigation>
    <router-view></router-view>
    <pageFooter></pageFooter>
  </div>
</template>

<script>
import navigation from '@/components/page/Navigation.vue'
import pageFooter from '@/components/page/Footer.vue'
import 'bootstrap'

export default {
  components: {
    navigation,
    pageFooter
  },
  created () {
    // FIXME
    this.$auth
      .load()
      .then(() => {
        this.$store.dispatch('setUser', this.$auth.user())
      })
  }
}
</script>

<style lang="scss">
@import '@/assets/style/custom.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import 'vue-select/dist/vue-select.css';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
