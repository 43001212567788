import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

import {
  faUserCircle,
  faSignOutAlt,
  faCircle,
  faTrash,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserCircle,
  faSignOutAlt,
  faCircle,
  faTrash,
  faExclamation
)

export default (app: any) => {
  app
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('font-awesome-layers', FontAwesomeLayers)
}
