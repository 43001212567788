<template>
  <div class="container-fluid">
    <div v-if="$auth.check({ [organisation.uuid]: ['admin'] }, 'orgRoles')" class="row mb-4">
      <div class="col-12">
        <router-link :to="{ name: 'newLocation' }" class="btn btn-primary float-right">{{ $t('po_lo_bt_loc-create') }}</router-link>
      </div>
    </div>
    <div class="row">
      <div v-if="restaurants" class="col-12">
        <ul v-if="restaurants" class="list-group">
          <li v-for="r in restaurants" :key="r.id" class="list-group-item">
            <restaurant-overview :restaurant="r"></restaurant-overview>
          </li>
        </ul>
        <ul v-else class="list-group">
          <li class="list-group-item">
            {{ $t('po_lo_tx_no_locations') }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import restaurantOverview from '@/components/RestaurantOverview.vue'

export default {
  components: {
    restaurantOverview
  },
  data: function () {
    return {
      restaurants: []
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    }
  },
  created: function () {
    this.axios.get('/vendor/restaurants')
      .then((res) => {
        this.restaurants = res.data.data
      })
  }

}
</script>
