<template>
  <div class="update-password">
    <div v-if="responseStatus === 200" class="alert alert-success" role="alert">
      {{ $t('po_ac_tx_change_passwort_success') }}
    </div>
    <div v-else-if="responseStatus === 403" class="alert alert-danger" role="alert">
      {{ $t('po_ac_tx_change_passwort_error') }}
    </div>
    <div v-else-if="responseStatus != 0" class="alert alert-danger" role="alert">
      {{ $t('po_ac_tx_change_passwort_error') }}
    </div>
    <form id="update-password" @submit.prevent="updatePassword">
      <div class="mb-3">
        <input id="oldPassword" name="oldPassword" type="password" class="form-control" :class="{ 'is-invalid': v$.oldPassword.$error }"
          :placeholder="$t('po_ac_tx_current_password')" v-model="oldPassword">
        <div class="invalid-feedback">{{ v$.oldPassword.$errors[0]?.$message }}</div>
      </div>
      <div class="mb-3">
        <input id="newPassword" name="newPassword" type="password" class="form-control" :class="{ 'is-invalid': v$.newPassword.$error }"
          :placeholder="$t('po_ac_tx_new_password')" ref="password" v-model="newPassword">
        <div class="invalid-feedback">{{ v$.newPassword.$errors[0]?.$message }}</div>
      </div>
      <div class="mb-3">
        <input id="repeatPassword" name="repeatPassword" type="password" class="form-control" :class="{ 'is-invalid': v$.repeatPassword.$error }"
          :placeholder="$t('po_ac_tx_repeat_password')" v-model="repeatPassword">
        <div class="invalid-feedback">{{ v$.repeatPassword.$errors[0]?.$message }}</div>
      </div>
      <div class="container">
        <div class="row">
          <div class="d-grid">
            <button type="submit" class="btn btn-primary btn-block">{{ $t('po_ac_bt_change_password') }}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@/config/i18n-validators'

export default {
  props: {
    msg: String
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      oldPassword: { required },
      newPassword: { required, minLength: minLength(8) },
      repeatPassword: { required, sameAs: sameAs(this.newPassword) }
    }
  },
  data: function () {
    return {
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      responseStatus: 0,
      updated: false
    }
  },
  methods: {
    async updatePassword () {
      this.updateDisabled = true
      this.error = false
      if (await this.v$.$validate()) {
        const params = {
          oldPassword: this.oldPassword,
          newPassword: this.newPassword
        }
        this.axios.post('/auth/password', params).then((res) => {
          this.responseStatus = res.status
          this.updated = true
        }).catch((e) => {
          this.responseStatus = e.response.status
          this.updateDisabled = false
        })
      } else {
        this.updateDisabled = false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
