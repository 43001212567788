<template>
  <div class="account">
    <div class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <h2>{{ $t('po_ac_hl_change_password') }}</h2>
        <div class="card">
          <div class="card-body">
            <update-password></update-password>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import updatePassword from '@/components/UpdatePassword.vue'

export default {
  components: {
    updatePassword
  }
}
</script>
