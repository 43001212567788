<template>
  <div>
    <div class="mb-2">
      <label for="line1" class="form-label">{{ $t('po_ge_lb_address') }} <span v-if="textPostFix">{{textPostFix}}</span>*</label>
      <input id="line1" name="line1" type="text" class="form-control" :class="{'is-invalid': v$.address.line1.$error }"
        :placeholder="$t('po_ge_lb_address')" ref="name" v-model="address.line1">
      <div class="invalid-feedback">{{ v$.address.line1.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-2">
      <input id="line2" name="line2" type="text" class="form-control" :class="{'is-invalid': v$.address.line2.$error }"
        placeholder="" ref="name" v-model="address.line2">
      <div class="invalid-feedback">{{ v$.address.line2.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <input id="line3" name="line3" type="text" class="form-control" :class="{'is-invalid': v$.address.line3.$error }"
        placeholder="" ref="line3" v-model="address.line3">
      <div class="invalid-feedback">{{ v$.address.line3.$errors[0]?.$message }}</div>
    </div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label for="postcode" class="form-label">{{ $t('po_ge_lb_postcode') }}*</label>
        <input id="postcode" name="postcode" type="text" class="form-control" :class="{'is-invalid': v$.address.postcode.$error }"
          :placeholder="$t('po_ge_lb_postcode')" ref="name" v-model="address.postcode">
        <div class="invalid-feedback">{{ v$.address.postcode.$errors[0]?.$message }}</div>
      </div>
      <div class="col-md-8">
        <label for="city" class="form-label">{{ $t('po_ge_lb_city') }}*</label>
        <input id="city" name="city" type="text" class="form-control" :class="{'is-invalid': v$.address.city.$error }"
          :placeholder="$t('po_ge_lb_city')" ref="name" v-model="address.city">
        <div class="invalid-feedback">{{ v$.address.city.$errors[0]?.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="country" class="form-label">{{ $t('po_ge_lb_country') }}*</label>
      <v-select :options="[{label: 'Österreich', code: 'at'}]" :reduce="country => country.code" v-model="address.country" name="country" :clearable="false"></v-select>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@/config/i18n-validators'

export default {
  props: {
    modelValue: Object,
    textPostFix: String
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      address: {
        line1: { required },
        line2: { },
        line3: { },
        postcode: { required },
        city: { required },
        country: { required }
      }

    }
  },
  computed: {
    address: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
