import * as validators from '@vuelidate/validators'
import i18n from '@/config/i18n'

const { createI18nMessage } = validators

// extract the `t` helper, should work for both Vue 2 and Vue 3 versions of vue-i18n
const t = function (path: string, params: { model: string, property: string, [key: string]: any }): string {
  const { t } = i18n.global || i18n
  params.fieldname = t(`validations.fieldnames.${params.property}`)
  return t(path, params)
}

// pass `t` and create your i18n message instance
const withI18nMessage = createI18nMessage({ t })

// wrap each validator.
export const required = withI18nMessage(validators.required)
export const email = withI18nMessage(validators.email)
export const minLength = withI18nMessage(validators.minLength, { withArguments: true })
export const sameAs = withI18nMessage(validators.sameAs, { withArguments: true })
