import { createApp } from 'vue'

import App from './App.vue'
import axios from './config/axios'
import store from './store'
import router from './router'
import i18n from './config/i18n'
import auth from './config/auth'
import fontAwsome from './config/fontAwsome'

import vSelect from 'vue-select'

createApp(App)
  .use(axios)
  .use(store)
  .use(router)
  .use(auth)
  .use(i18n)
  .use(fontAwsome)
  .component('v-select', vSelect)
  .mount('#app')
