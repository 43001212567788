import { createAuth } from '@websanova/vue-auth'
import * as driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.js'
import * as driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js'
import * as driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js'

const auth = (app: any) => {
  app.use(
    createAuth({
      plugins: {
        http: app.axios,
        router: app.router
      },
      drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter
      },
      options: {
        loginData: { url: 'auth/partner/login', fetchUser: true },
        refreshData: { enabled: false },
        fetchData: { enabled: true },
        parseUserData: function (data: any) {
          const roles: { [k: string]: any } = {}
          data.userRoles = data.userRoles || []
          data.userRoles.forEach((roleDef: any) => {
            roles[roleDef.restaurantId] = roles[roleDef.restaurantId] || []
            roles[roleDef.restaurantId].push(roleDef.role)
          })
          data.roles = roles

          const orgRoles: { [k: string]: any } = {}
          data.organisationRoles = data.organisationRoles || []
          data.organisationRoles.forEach((roleDef: any) => {
            orgRoles[roleDef.organisationUuid] = orgRoles[roleDef.organisationUuid] || []
            orgRoles[roleDef.organisationUuid].push(roleDef.role)
          })
          data.orgRoles = orgRoles
          return data
        }
      }
    })
  )
}

export default auth
