<template>
  <div>
    <contract-overview v-if="contract" :contract="contract" class="mb-3"/>
    <div v-else class="mb-3">{{ $t('po_le_tx_no_contract') }}</div>

    <div v-if="contract || otherContracts.length >= 1">
      <div class="dropdown">
        <button class="btn btn-danger dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" data-bs-target="dropdownMenuItems" aria-haspopup="true" aria-expanded="false">
          {{ $t('po_le_bt_assign_contract') }}
        </button>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="dropdownMenuItems">
          <a v-for="c in otherContracts" :key="c.uuid" @click="changeContract(c.uuid)" :class="c.locations.length >= c.quantity ? 'disabled' : ''" class="dropdown-item" href="#">{{ $t('po_ge_tx_product_name') }} ({{ c.locations.length }} / {{ $tc('po_ge_tx_unit_locations', c.quantity) }})</a>
          <a v-if="contract" data-bs-toggle="modal" data-bs-target="#removeContractModal" class="dropdown-item" href="#">{{ $t('po_le_tx_remove_contract') }}</a>
        </div>
      </div>
    </div>

    <modal id="removeContractModal" :title="$t('po_le_tx_remove_contract')" :text="$t('po_le_tx_remove_contract_confirmation')" :subText="$t('po_le_tx_remove_contract_confirmation_sub')" :textConfirm="$t('po_le_tx_remove_contract')" :textClose="$t('po_ge_bt_cancel')" @confirm="removeContract()"/>
  </div>
</template>

<script>
import contractOverview from '@/components/organisation/ContractOverview.vue'
import modal from '@/components/common/Modal.vue'

export default {
  components: {
    contractOverview,
    modal
  },
  props: {
    location: Object
  },
  data: function () {
    return {
      product: null,
      selectedContractUuid: null
    }
  },
  computed: {
    contract: function () {
      return this.$store.state.organisation.organisationContracts.find(c => c.locations.some(l => l.uuid === this.location.uuid))
    },
    otherContracts: function () {
      return this.contract ? this.$store.state.organisation.organisationContracts.filter(c => c.uuid !== this.contract.uuid) : this.$store.state.organisation.organisationContracts
    }
  },
  methods: {
    changeContract: function (contractUuid) {
      this.$store.dispatch('updateLocationContract', {
        contractUuid,
        locationUuid: this.location.uuid
      })
    },
    removeContract: function () {
      this.$store.dispatch('removeLocationContract', {
        contractUuid: this.contract.uuid,
        locationUuid: this.location.uuid
      })
    }
  }
}
</script>
