<template>
  <div class="container text-center">
    <div v-if="waiting" class="p-5 bg-light border">
      <h1 class="display-4">Wilkommen!</h1>
      <p class="lead">Ihre E-Mail Adresse wird überprüft.</p>
    </div>
    <div v-if="!waiting && valid" class="p-5 bg-light border">
      <h1 class="display-4">Wilkommen!</h1>
      <p class="lead">Ihre E-Mail Adresse ist bestätigt.</p>
      <p>Gehen Sie zur Login-Seite um sich einzuloggen</p>
      <p class="lead">
        <router-link :to="{name: 'login'}" class="btn btn-primary btn-lg">Zum Login</router-link>
      </p>
    </div>
    <div v-if="!waiting && !valid" class="p-5 bg-light border">
      <h1 class="display-4">Oh Nein!</h1>
      <p class="lead">Ihre E-Mail Adresse konnte nicht bestätigt werden.</p>
      <p>Wenn Sie Ihre E-Mail Adresse schon bestätigt haben, versuchen Sie sich einzuloggen.</p>
      <p class="lead">
        <router-link :to="{name: 'login'}" class="btn btn-primary btn-lg">Zum Login</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    token: String
  },
  data () {
    return {
      waiting: true,
      valid: false
    }
  },
  created () {
    this.axios.get(`/auth/verify/${this.token}`)
      .then(() => {
        this.waiting = false
        this.valid = true
      }).catch(() => {
        this.waiting = false
        this.valid = false
      })
  }
}
</script>
