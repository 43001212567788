<template>
  <div v-if="contract">
      <ul class="d-flex w-100 justify-content-between list-group list-group-flush">
        <li v-for="l in contract.locations" :key="l.uuid" class="list-group-item">
          <router-link :to="{ name: 'restaurant', params: { id: l.id } }">{{ l.name }}</router-link>
        </li>
      </ul>
  </div>
</template>

<script>
export default {
  props: {
    contract: Object
  }
}
</script>
