<template>
  <div class="container-fluid">
    <h1>{{organisation.name}}</h1>
    <h2 class="mt-5">{{ $t('po_or_he_user') }}</h2>
    <organisation-users/>
    <organisation-invite-user/>
  </div>
</template>

<script>
import organisationUsers from '@/components/OrganisationUsers.vue'
import organisationInviteUser from '@/components/OrganisationInviteUser.vue'

export default {
  components: {
    organisationUsers,
    organisationInviteUser
  },
  data: function () {
    return {
      // isMember: this.$auth.check({ [this.id]: ['waiter'] }),
      // isAdmin: this.$auth.check({ [this.id]: ['admin'] })
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    organisationUsers: function () {
      return this.$store.state.organisation.organisationUsers || []
    }
  }
}
</script>

<style scoped lang="scss">
</style>
