<template>
  <div class="container-fluid">
    <organisation-contracts/>
  </div>
</template>

<script>
import organisationContracts from '@/components/organisation/OrganisationContracts'

export default {
  components: {
    organisationContracts
  },
  data: function () {
    return {
      // isMember: this.$auth.check({ [this.id]: ['waiter'] }),
      // isAdmin: this.$auth.check({ [this.id]: ['admin'] })
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    organisationUsers: function () {
      return this.$store.state.organisation.organisationUsers || []
    }
  }
}
</script>
