<template>
    <div class="row justify-content-center">
      <div class="card col-11 col-md-8 my-2">
        <div class="card-body">
          <h5 class="card-title">{{ $t('po_cg_he_organisation') }}</h5>
          <div v-if="!organisation">
            <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_cg_tx_create_organisation') }}</h6>
            <organisationForm v-model="organisationData"></organisationForm>
          </div>
          <div v-else>
            <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_cg_tx_existing_organisation') }}</h6>
            {{ organisation.name }}
          </div>
        </div>
      </div>
        <div class="card col-11 col-md-8 my-2">
            <div class="card-body">
                <h5 class="card-title">{{ $t('po_ge_tx_product_name') }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_ge_tx_product_desc') }}</h6>
                <hr>
                <p class="card-text">
                  {{ $tc('po_ge_tx_unit_locations', quantity) }}
                </p>
                <hr>
                <div class="form-check">
                  <input id="acceptLegal" name="acceptLegal" type="checkbox" v-model="acceptLegal" class="form-check-input">
                  <label class="form-check-label" for="acceptLegal">
                    <i18n-t keypath="po_cg_lb_legal" tag="label" for="acceptLegal" class="form-check-label">
                      <template v-slot:terms>
                        <a href="https://www.tapget.com/legal">{{ $t('po_cg_tx_terms') }}</a>
                      </template>
                      <template v-slot:privacy>
                        <a href="https://www.tapget.com/privacy-policy">{{ $t('po_cg_tx_privacy') }}</a>
                      </template>
                    </i18n-t>
                    *
                  </label>
                </div>
                <div class="d-grid">
                  <button v-if="price.currency" class="col btn btn-primary mt-3" @click="order" :disabled="!acceptLegal">
                      <i18n-t keypath="po_cg_bt_order" for="acceptLegal" class="form-check-label">
                        <template v-slot:price>
                          {{ parseFloat(finalPrice).toLocaleString('de', { style: 'currency', currency: price.currency }) }}
                        </template>
                        <template v-slot:timeunit>
                          {{ $t(`po_ge_tx_timeunit_${price.timeUnit}`) }}
                        </template>
                      </i18n-t>
                  </button>
                  <p class="text-muted small text-center">
                    {{ $t('po_cg_tx_vat') }}
                  </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import useVuelidate from '@vuelidate/core'
import organisationForm from '@/components/organisation/OrganisationForm.vue'

export default {
  components: {
    organisationForm
  },
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data () {
    return {
      productUuid: this.$route.query.product,
      priceUuid: this.$route.query.price,
      quantity: +this.$route.query.quantity || 1,
      checkoutSession: null,
      timeUnitTexts: {
        SINGLE: 'einmalig',
        MONTHLY: 'pro Monat',
        YEARLY: 'pro Jahr'
      },
      product: {},
      organisationData: {
        name: '',
        email: this.$store.state.user.email,
        invoiceAddress: {
          country: 'at'
        }
      },
      acceptLegal: false
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation
    },
    price: function () {
      return (this.product.prices || []).find((price) => price.uuid === this.priceUuid) || {}
    },
    finalPrice: function () {
      return this.price.unitPrice * this.quantity
    },
    attributes: function () {
      return Object.keys(this.price.attributes).reduce((acc, key) => {
        acc[key] = this.$route.query[key]
        return acc
      }, {})
    }
  },
  methods: {
    order: async function () {
      if (await this.v$.$validate()) {
        let organisationUuid = null
        if (this.organisation) {
          organisationUuid = this.organisation.uuid
        } else {
          const organisationRes = await this.axios.post('/organisations/', this.organisationData)
          await this.$auth.fetch()
          await this.$store.dispatch('fetchUserOrganisation')
          organisationUuid = organisationRes.data.uuid
        }

        const params = {
          productUuid: this.productUuid,
          priceUuid: this.priceUuid,
          quantity: this.quantity
        }
        const checkoutSession = await this.axios.post(`/organisations/${organisationUuid}/contracts/checkoutSession`, params)
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
        stripe.redirectToCheckout({
          sessionId: checkoutSession.data.checkoutSessionId
        })
      }
    }
  },
  created: function () {
    this.axios.get(`/partners/products/${this.productUuid}`)
      .then((res) => {
        this.product = res.data
      })
  }
}
</script>
