<template>
  <user-auth></user-auth>
</template>

<script>
import userAuth from '@/components/UserAuth.vue'

export default {
  components: {
    userAuth
  }
}
</script>
