import { createStore } from 'vuex'

import OrganisationStore from './OrganisationStore'

export interface StoreState {
  user: any
  allUsers: any
}

const store = createStore({
  modules: {
    organisation: OrganisationStore
  },
  state: {
    user: {},
    allUsers: {}
  },
  getters: {
    restaurantUsers: (state) => (restaurantId: any): any => {
      // FIXME
      // return state.allUsers[restaurantId]
    }
  },
  mutations: {
    SET_USER (state, payload) {
      state.user = payload
    },
    SET_RESTAURANT_USERS (state, payload) {
      // FIXME
      // state.allUsers[payload.restaurantId] = payload.users
    }
  },
  actions: {
    setUser (context, user) {
      context.commit('SET_USER', user)
      context.dispatch('fetchUserOrganisations')
      context.dispatch('fetchUserOrganisation')
    },
    fetchRestaurantUsers (context, restaurantId) {
      // this.$axios.get(`/vendor/restaurants/${restaurantId}/users`)
      //   .then((res) => {
      //     context.commit('SET_RESTAURANT_USERS', {
      //       restaurantId: restaurantId,
      //       users: res.data.data
      //     })
      //   })
    }
  }
})

export default (app: any) => {
  return app.use(store)
}
