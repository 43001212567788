<template>
  <nav id="nav" class="navbar navbar-expand-md navbar-light">
    <div class="container-fluid">
      <!-- :to="{ name: 'home' }" -->
      <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }" class="navbar-brand">
        <img :alt="$t('po_ge_me_tapget_logo')" src="../../assets/logo.svg" width="102" height="30">
      </router-link>

      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu" aria-controls="menu" aria-expanded="false" :aria-label="$t('po_ge_me_toggle_navigation')">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="menu" class="collapse navbar-collapse">
        <ul v-if="$auth.check()" class="navbar-nav">
          <li class="nav-item" :class="{active: $route.path == '/'}">
            <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }" class="nav-link">{{ $t('po_ge_me_home') }}</router-link>
          </li>
          <li v-if="$auth.check({ [organisation.uuid]: ['admin'] }, 'orgRoles')" class="nav-item" :class="{active: $route.path == '/organisation'}">
            <router-link :to="{ name: 'organisation', params: { locale: $i18n.locale } }" class="nav-link">{{ $t('po_ge_me_organisation') }}</router-link>
          </li>
          <li v-if="$auth.check({ [organisation.uuid]: ['admin'] }, 'orgRoles')" class="nav-item" :class="{active: $route.path == '/organisation'}">
            <router-link :to="{ name: 'contracts', params: { locale: $i18n.locale } }" class="nav-link">{{ $t('po_ge_me_contracts') }}</router-link>
          </li>
          <li class="nav-item" :class="{active: $route.path == '/locations'}">
            <router-link :to="{ name: 'locations', params: { locale: $i18n.locale } }" class="nav-link">{{ $t('po_ge_me_locations') }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item">
            <form class="form-inline nav-link">
              <locales></locales>
            </form>
          </li>
          <li v-if="$auth.check() && otherOrganisations.length" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="organisations" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{organisation.name}}
            </a>
            <div class="dropdown-menu" aria-labelledby="organisations">
              <a v-for="o in otherOrganisations" :key="o.uuid" @click="selectOrganisation(o.uuid)" class="dropdown-item" href="#">{{o.name}}</a>
            </div>
          </li>
          <li v-if="$auth.check()" class="nav-item">
            <router-link :to="{ name: 'account', params: { locale: $i18n.locale } }" class="nav-link" :aria-label="$t('po_ge_me_account')"><font-awesome-icon icon="user-circle" size="lg"/></router-link>
          </li>
          <li v-if="$auth.check()" class="nav-item">
            <a class="nav-link" @click="logout" href="#" :aria-label="$t('po_ge_me_logout')"><font-awesome-icon icon="sign-out-alt" size="lg"/></a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import locales from './Locales'

export default {
  components: {
    locales
  },
  computed: {
    organisations: function () {
      return this.$store.state.organisation.organisations || []
    },
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    otherOrganisations: function () {
      return this.organisations.filter(org => org.uuid !== this.organisation.uuid) || []
    }
  },
  methods: {
    selectOrganisation: function (organisationUuid) {
      this.$store.dispatch('fetchOrganisation', organisationUuid)
    },
    logout: function () {
      this.$auth.logout({
        redirect: { name: 'login', params: { locale: this.$i18n.locale } }
      })
        .then(() => {
          this.$store.dispatch('setUser', null)
        })
    }
  }
}
</script>

<style lang="scss">
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #e62647;
    }
  }
}
</style>
