<template>
  <table class="table">
    <thead>
      <tr class="d-flex">
        <th scope="col" class="col-10">{{ $t('po_or_hl_email') }}</th>
        <th scope="col" class="col-1 text-center">{{ $t('po_or_hl_admin') }}</th>
        <th scope="col" class="col-1 text-center">{{ $t('po_or_hl_delete') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="u in sortedUsers" :key="u.uuid" class="d-flex">
        <td class="col-10">{{u.email}} <span v-if="u.uuid === $auth.user().uuid">(Sie)</span></td>
        <td class="col-1 text-center">
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" :id="`${u.uuid}-admin`" :disabled="u.uuid === $auth.user().uuid || loading[`${u.uuid}`]" :checked="hasRole(u, 'admin')" @change="switchRole(u, 'admin')">
            <label class="custom-control-label" :for="`${u.uuid}-admin`"></label>
          </div>
        </td>
        <td class="col-1 text-center">
            <button type="button" v-if="confirmDelete !== u.uuid" @click="confirmDeleteUser(u)" :disabled="u.uuid === $auth.user().uuid || loading[`${u.uuid}`]" class="btn btn-link p-0">
              <font-awesome-layers class="fa-lg">
                <font-awesome-icon icon="trash" class="text-warning"/>
              </font-awesome-layers>
            </button>
            <button type="button" v-if="confirmDelete === u.uuid" @click="deleteUser(u)" :disabled="u.uuid === $auth.user().uuid || loading[`${u.uuid}`]" class="btn btn-link p-0">
              <font-awesome-layers class="fa-lg">
                <font-awesome-icon icon="trash" class="text-danger"/>
                <font-awesome-icon icon="exclamation" class="text-light" style="font-size: 0.5em; margin-top: 0.65em"/>
              </font-awesome-layers>
            </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {},
  data: function () {
    return {
      responseStatus: 0,
      loading: {},
      confirmDelete: null
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    sortedUsers: function () {
      const sorted = (this.$store.state.organisation.organisationUsers || []).slice()
      sorted.sort((a, b) => a.email.localeCompare(b.email))
      return sorted
    }
  },
  methods: {
    hasRole: function (user, role) {
      return user.organisationRoles.some((organisationRole) => organisationRole.role === role)
    },
    addRole: function (user, role) {
      this.loading[user.uuid] = true

      const roles = user.organisationRoles.map(organisationRole => ({ role: organisationRole.role }))
      roles.push({ role: role })

      this.axios.put(`/organisations/${this.organisation.uuid}/users/${user.uuid}`, roles).then(res => {
        this.responseStatus = res.status
        this.$store.dispatch('fetchOrganisation', this.organisation.uuid)
      }).catch((e) => {
        this.responseStatus = e.response.status
      }).finally(() => {
        this.loading[user.uuid] = false
      })
    },
    removeRole: function (user, role) {
      this.loading[user.uuid] = true

      const roles = user.organisationRoles.filter(organisationRole => organisationRole.role !== role).map(organisationRole => ({ role: organisationRole.role }))
      this.axios.put(`/organisations/${this.organisation.uuid}/users/${user.uuid}`, roles).then(res => {
        this.responseStatus = res.status
        this.$store.dispatch('fetchOrganisation', this.organisation.uuid)
      }).catch((e) => {
        this.responseStatus = e.response.status
      }).finally(() => {
        this.loading[user.uuid] = false
      })
    },
    switchRole: function (user, role) {
      if (event.target.checked) {
        this.addRole(user, role)
      } else {
        this.removeRole(user, role)
      }
    },
    confirmDeleteUser: function (user) {
      this.confirmDelete = user.uuid
    },
    deleteUser: function (user) {
      this.loading[user.uuid] = true

      this.axios.put(`/organisations/${this.organisation.uuid}/users/${user.uuid}`, []).then(res => {
        this.responseStatus = res.status
        this.$store.dispatch('fetchOrganisation', this.organisation.uuid)
        this.$set(this.loading, `${user.uuid}`, false)
        this.confirmDelete = null
      }).catch((e) => {
        this.responseStatus = e.response.status
        this.$set(this.loading, `${user.uuid}`, false)
        this.confirmDelete = null
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
