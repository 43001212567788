<template>
    <div v-if="product" class="product">
      <div>
        <div class="card-body">
          <h5 class="card-title">{{ $t('po_ge_tx_product_name') }}</h5>
          <p class="card-text">{{ $t('po_ge_tx_product_desc') }}</p>

          <div class="row mt-5">
            <div class="col-12">
              <div class="mb-3">
                <label for="country" class="form-label">{{ $t('po_hm_lb_country') }}</label>
                <v-select :options="[{ label: 'Österreich', code: 'at' }, { label: 'Deutschland', code: 'de' }]" :reduce="country => country.code" v-model="country" :clearable="false"></v-select>
                <small class="form-text text-muted">{{ $t('po_hm_tx_country_info') }}</small>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-12">
              <input type="range" class="form-range" id="customRange1" v-model.number="quantity" min=1 :max="maxQuantity + 1">
              <span v-if="quantity <= maxQuantity">
                {{ $tc('po_ge_tx_unit_locations', quantity) }}
              </span>
            </div>
          </div>

          <div v-if="quantity <= maxQuantity" class="row mt-5">
            <div class="card-group">
              <div class="card">
                <div class="card-body pb-0" v-if="monthlyPrice">
                  <h5 class="card-title">{{ $t('po_hm_tx_monthly') }}</h5>
                  <div class="mt-3 card-text">
                    <p>{{ $t('po_hm_tx_monthly_info') }}</p>
                    <p>{{ $t('po_hm_tx_trial') }}</p>
                  </div>
                </div>
                <div class="card-footer bg-transparent border-0 pt-0">
                  <div class="mt-3 card-text">
                    <div>
                        {{ parseFloat(monthlyFinalPrice).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                    </div>
                    <div>
                        <small class="text-muted">{{ $t(`po_ge_tx_timeunit_${monthlyPrice.timeUnit}`) }}</small>
                    </div>
                  </div>
                  <router-link :to="{ name: 'checkout', query: monthlyQueryParams }" class="col btn btn-primary d-grid mt-3">{{ $t('po_hm_bt_book_monthly') }}</router-link>
                </div>
              </div>
              <div class="card">
                <div class="card-body pb-0" v-if="yearlyPrice">
                  <h5 class="card-title">{{ $t('po_hm_tx_yearly') }}</h5>
                  <div class="mt-3 card-text">
                    <p>{{ $t('po_hm_tx_yearly_info') }}</p>
                    <p>{{ $t('po_hm_tx_trial') }}</p>
                  </div>
                </div>
                <div class="card-footer bg-transparent border-0 pt-0">
                  <div class="mt-3 card-text">
                    <div>
                        {{ parseFloat(yearlyFinalPrice).toLocaleString('de', { style: 'currency', currency: 'EUR' }) }}
                    </div>
                    <div>
                        <small class="text-muted">{{ $t(`po_ge_tx_timeunit_${yearlyPrice.timeUnit}`) }}</small>
                    </div>
                  </div>
                  <router-link :to="{ name: 'checkout', query: yearlyQueryParams }" class="col btn btn-primary d-grid mt-3">{{ $t('po_hm_bt_book_yearly') }}</router-link>
                </div>
              </div>
            </div>
          </div>

          <div v-else class="row mt-5">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-body" v-if="monthlyPrice">
                  <h5 class="card-title">{{ $t('po_hm_he_individual_offer') }}</h5>
                    <i18n-t keypath="po_hm_tx_individual_offer" tag="p">
                      <a href="mailto:contact@tapget.com">contact@tapget.com</a>
                    </i18n-t>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: {
    productUuid: String
  },
  data: function () {
    return {
      product: null,
      maxQuantity: 9,
      timeUnitTexts: {
        SINGLE: 'einmalig',
        MONTHLY: 'pro Monat',
        YEARLY: 'pro Jahr'
      },
      priceSelection: null,
      quantity: 1,
      country: 'at'
    }
  },
  computed: {
    monthlyPrice: function () {
      return this.product.prices.find((it) => it.timeUnit === 'MONTHLY' && it.attributes.country === this.country)
    },
    yearlyPrice: function () {
      return this.product.prices.find((it) => it.timeUnit === 'YEARLY' && it.attributes.country === this.country)
    },
    monthlyFinalPrice: function () {
      return this.monthlyPrice.unitPrice * this.quantity
    },
    yearlyFinalPrice: function () {
      return this.yearlyPrice.unitPrice * this.quantity
    },
    monthlyQueryParams: function () {
      return {
        product: this.product.uuid,
        price: this.monthlyPrice.uuid,
        quantity: this.quantity,
        country: this.country
      }
    },
    yearlyQueryParams: function () {
      return {
        product: this.product.uuid,
        price: this.yearlyPrice.uuid,
        quantity: this.quantity,
        country: this.country
      }
    }
  },
  created: function () {
    this.axios.get(`/partners/products/${this.productUuid}`)
      .then((res) => {
        this.product = res.data
        let defaultPriceSelection = 'MONTHLY'
        if (this.product.prices.length === 1) {
          defaultPriceSelection = this.product.prices[0].timeUnit
        } else {
          defaultPriceSelection = (this.product.prices.find((it) => it.timeUnit === 'SINGLE') ||
              this.product.prices.find((it) => it.timeUnit === 'MONTHLY') ||
              this.product.prices.find((it) => it.timeUnit === 'YEARLY') ||
              {}).timeUnit
        }
        this.priceSelection = defaultPriceSelection
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.product {
  width: 600px;
  text-align: center;
}
</style>
