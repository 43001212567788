import axios from './http'
import VueAxios from 'vue-axios'

// Axios
export default (app: any) => {
  app.use(VueAxios, axios)
  app.axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL

  app.axios = axios
  app.$http = axios

  app.config.globalProperties.axios = axios
  app.config.globalProperties.$http = axios

  // app.axios.interceptors.request.use(function (config: any) {
  //   const token = app.auth.token()
  //   if (token) {
  //     config.headers.Authorization = `Bearer ${token}`
  //   }
  //   return config
  // }, function (error: any) {
  //   return Promise.reject(error)
  // })
}
