<template>
  <div>
    <div class="row mb-3">
      <div class="col-md-4">
        <label for="lat" class="form-label">{{ $t('po_lc_lb_lat') }}*</label>
        <input id="lat" name="lat" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.lat.$error }"
          :placeholder="$t('po_lc_tx_lat')" ref="lat" v-model="locationDetails.lat">
        <div class="invalid-feedback">{{ v$.locationDetails.lat.$errors[0]?.$message }}</div>
      </div>
      <div class="col-md-4">
        <label for="lat" class="form-label">{{ $t('po_lc_lb_lon') }}*</label>
        <input id="lon" name="lon" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.lon.$error }"
               :placeholder="$t('po_lc_tx_lon')" ref="lon" v-model="locationDetails.lon">
        <div class="invalid-feedback">{{ v$.locationDetails.lon.$errors[0]?.$message }}</div>
      </div>
    </div>

    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" id="allowReviews" v-model="locationDetails.allowReviews">
      <label class="custom-control-label" :for="allowReviews">{{ $t('po_lc_lb_allow_reviews') }}</label>
    </div>

    <div class="mb-3">
      <label for="phone" class="form-label">{{ $t('po_lc_lb_phone') }}*</label>
      <input id="phone" name="phone" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.phone.$error }"
        :placeholder="$t('po_lc_tx_phone')" ref="password" v-model="locationDetails.phone">
      <div class="invalid-feedback">{{ v$.locationDetails.phone.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="homepage" class="form-label">{{ $t('po_lc_lb_homepage') }}*</label>
      <input id="homepage" name="homepage" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.homepage.$error }"
        :placeholder="$t('po_lc_tx_homepage')" ref="password" v-model="locationDetails.homepage">
      <div class="invalid-feedback">{{ v$.locationDetails.homepage.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="facebook" class="form-label">{{ $t('po_lc_lb_facebook') }}</label>
      <input id="facebook" name="facebook" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.facebook.$error }"
        :placeholder="$t('po_lc_tx_facebook')" ref="password" v-model="locationDetails.facebook">
      <div class="invalid-feedback">{{ v$.locationDetails.facebook.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="twitter" class="form-label">{{ $t('po_lc_lb_twitter') }}</label>
      <input id="twitter" name="twitter" type="text" class="form-control" :class="{ 'is-invalid': v$.locationDetails.twitter.$error }"
        :placeholder="$t('po_lc_tx_twitter')" ref="password" v-model="locationDetails.twitter">
      <div class="invalid-feedback">{{ v$.locationDetails.twitter.$errors[0]?.$message }}</div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/config/i18n-validators'

export default {
  props: {
    modelValue: Object
  },
  components: {
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      locationDetails: {
        lat: { required },
        lon: { required },
        allowReviews: { },
        email: { required, email },
        phone: { required },
        homepage: { required },
        facebook: { },
        twitter: { }
      }
    }
  },
  computed: {
    locationDetails: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
