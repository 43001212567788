<template>
  <div class="footer">
    <ul class="nav justify-content-center">
    <li class="nav-item"><a href="https://www.tapget.com/imprint" class="nav-link text-muted">{{ $t('po_ge_ft_imprint') }}</a></li>
    <li class="nav-item"><a href="https://www.tapget.com/legal" class="nav-link text-muted">{{ $t('po_ge_ft_terms') }}</a></li>
    <li class="nav-item"><a href="https://www.tapget.com/privacy-policy" class="nav-link text-muted">{{ $t('po_ge_ft_privacy') }}</a></li>
    </ul>
  </div>
</template>

<style lang="scss">
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px !important;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
.nav-link {
  line-height: 44px;
}
</style>
