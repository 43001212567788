import {
  Store as VuexStore,
  DispatchOptions,
  CommitOptions,
  Module
} from 'vuex'

import axios from '../config/http'

const store: Module<any, any> = {
  state: {
    organisations: [],
    organisation: null,
    organisationUsers: [],
    organisationContracts: []
  },
  mutations: {
    SET_ORGANISATIONS (state: any, payload: any) {
      state.organisations = payload
    },
    SET_ORGANISATION (state: any, payload: any) {
      state.organisation = payload
    },
    SET_ORGANISATION_USERS (state: any, payload: any) {
      state.organisationUsers = payload
    },
    SET_ORGANISATION_CONTRACTS (state: any, payload: any) {
      state.organisationContracts = payload
    }
  },
  actions: {
    fetchUserOrganisations (context: any) {
      const organisationRoles: Array<any> = (context.rootState.user?.organisationRoles || [])
      const uniqueOrganisationUuids = [...new Set(organisationRoles.map(organisationRole => organisationRole.organisationUuid))]
      Promise.all(uniqueOrganisationUuids.map(uniqueOrganisationUuid => axios.get(`/organisations/${uniqueOrganisationUuid}`))).then(ress => {
        const organisations = ress.map(res => {
          return {
            uuid: res.data.uuid,
            name: res.data.name,
            role: organisationRoles.find(role => role.organisationUuid === res.data.uuid).role
          }
        })
        context.commit('SET_ORGANISATIONS', organisations)
      })
    },
    fetchUserOrganisation (context: any) {
      if (context.rootState.user) {
        const organisationRole = (context.rootState.user.organisationRoles || [])[0]
        if (organisationRole) {
          this.dispatch('fetchOrganisation', organisationRole.organisationUuid)
        }
      } else {
        this.dispatch('fetchOrganisation', null)
      }
    },
    updateOrganisation (context: any) {
      context.dispatch('fetchOrganisation', context.state.organisation.uuid)
    },
    async fetchOrganisation (context: any, organisationUuid: any) {
      if (organisationUuid) {
        const organisationsRes = await axios.get(`/organisations/${organisationUuid}`)
        context.commit('SET_ORGANISATION', organisationsRes.data)
        const usersRes = await axios.get(`/organisations/${organisationUuid}/users`)
        context.commit('SET_ORGANISATION_USERS', usersRes.data)
        this.dispatch('fetchOrganisationContracts', organisationUuid)
      } else {
        context.commit('SET_ORGANISATION', null)
        context.commit('SET_ORGANISATION_USERS', [])
        context.commit('SET_ORGANISATION_CONTRACTS', [])
      }
    },
    async fetchOrganisationContracts (context: any, organisationUuid: any) {
      const res = await axios.get(`/organisations/${organisationUuid}/contracts`)
      context.commit('SET_ORGANISATION_CONTRACTS', res.data)
    },
    // FIXME
    // setOrganisationUserRoles (context: any, userUuid: any, roles: any) {
    //   this.axios.put(`/organisations/${context.state.organisation.uuid}/users/${userUuid}`, roles)
    //     .then(res => {
    //       this.dispatch('fetchUserOrganisation')
    //     })
    // },
    async updateLocationContract (context: any, data: any) {
      await axios.patch(`/organisations/${context.state.organisation.uuid}/contracts/${data.contractUuid}/locations`, { locationUuid: data.locationUuid })
      this.dispatch('fetchOrganisationContracts', context.state.organisation.uuid)
    },
    async removeLocationContract (context: any, data: any) {
      await axios.delete(`/organisations/${context.state.organisation.uuid}/contracts/${data.contractUuid}/locations/${data.locationUuid}`)
      this.dispatch('fetchOrganisationContracts', context.state.organisation.uuid)
    }
  }
}

export default store
