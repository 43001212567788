<template>
  <div>
    <div v-if="!valid || updated" class="container">
      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-3" v-if="waiting">
          <p class="lead">Ihr Passwort-Link wird überprüft.</p>
        </div>
        <div class="container-fluid py-3" v-if="!waiting && !valid">
          <h1>Oh Nein!</h1>
          <p class="lead">Ihr Passwort-Link ist leider ungültig.</p>
          <p>Der Passwort-Link ist nur eine begrenzte Zeit gültig. Wenn Sie Ihr Passwort zurücksetzen wollen, fordern Sie bitte einen neuen Passwort-Link an.</p>
          <p class="lead">
            <router-link :to="{ name: 'forgotPassword' }" class="btn btn-primary btn-lg">Zum Passwort zurücksetzen</router-link>
          </p>
        </div>
        <div class="container-fluid py-3" v-if="updated">
          <p class="lead">Ihr Passwort wurde neu gesetzt.</p>
          <p>Gehe Sie zur Login-Seite um sich mit Ihrem neuen Passwort einzuloggen.</p>
          <p class="lead">
            <router-link :to="{ name: 'login' }" class="btn btn-primary btn-lg">Zum Login</router-link>
          </p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" v-if="valid && !updated">
      <div class="col-10 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="alert alert-danger" role="alert" v-if="error">
              Leider ist beim Setzen des Passwortes ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
            </div>
            <form id="Login" @submit.prevent="updatePassword">
              <div class="mb-3">
                <input id="password" name="password" type="password" class="form-control" :class="{ 'is-invalid': v$.password.$error }"
                  placeholder="Passwort" ref="password" v-model="password">
                <div class="invalid-feedback">{{ v$.password.$errors[0]?.$message }}</div>
              </div>
              <div class="mb-3">
                <input id="repeatPassword" name="repeatPassword" type="password" class="form-control" :class="{ 'is-invalid': v$.repeatPassword.$error }"
                  placeholder="Passwort wiederholen" v-model="repeatPassword">
                <div class="invalid-feedback">{{ v$.repeatPassword.$errors[0]?.$message }}</div>
              </div>
              <div class="d-grid">
                <button type="submit" class="btn btn-primary" :disabled="requestDisabled">Passwort setzen</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, sameAs } from '@/config/i18n-validators'

export default {
  props: {
    token: String
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      password: { required, minLength: minLength(8) },
      repeatPassword: { required, sameAs: sameAs(this.password) }
    }
  },
  data () {
    return {
      waiting: true,
      valid: false,
      updated: false,
      requestDisabled: false,
      password: '',
      repeatPassword: '',
      error: false
    }
  },
  created () {
    this.axios.get(`/auth/password/reset/${this.token}`)
      .then(() => {
        this.waiting = false
        this.valid = true
      }).catch(() => {
        this.waiting = false
        this.valid = false
      })
  },
  methods: {
    async updatePassword () {
      this.requestDisabled = true
      this.error = false
      if (await this.v$.$validate()) {
        const params = {
          password: this.password
        }

        this.axios.post(`/auth/password/reset/${this.token}`, params).then((res) => {
          this.updated = true
        }).catch(() => {
          this.error = true
          this.requestDisabled = false
        })
      } else {
        this.requestDisabled = false
      }
    }
  }
}
</script>
