<template>
  <div v-if="$auth.check({ [organisation.uuid]: ['admin'] }, 'orgRoles')">
    <div v-if="contracts.length" class="container">
      <div class="mb-5 p-5 bg-light border">
        <i18n-t keypath="po_ct_tx_manage_contracts" scope="global">
          <a href="#" @click="open">{{ $t('po_ct_tx_self_service_portal') }}</a>
        </i18n-t>
      </div>
    </div>
    <div v-else class="col-12">
      {{ $t('po_ct_tx_no_contracts') }}
    </div>
    <ul v-if="contracts" class="list-group">
      <li v-for="c in contracts" :key="c.uuid" class="list-group-item">
        <contract-overview :contract="c"></contract-overview>
        <contract-locations :contract="c"></contract-locations>
      </li>
    </ul>
  </div>
</template>

<script>
import contractOverview from './ContractOverview.vue'
import contractLocations from './ContractLocations.vue'

export default {
  components: {
    contractOverview,
    contractLocations
  },
  data: function () {
    return {
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    contracts: function () {
      return this.$store.state.organisation.organisationContracts || []
    }
  },
  methods: {
    open: async function () {
      event.preventDefault()
      const session = await this.axios.post(`/organisations/${this.organisation.uuid}/selfServiceSession/`)
      window.location.href = session.data.url
    }
  }
}
</script>
