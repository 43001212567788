<template>
  <div>
    <div class="mb-3">
      <label for="name" class="form-label">{{ $t('po_cg_lb_name') }}*</label>
      <input id="name" name="name" type="text" class="form-control" :class="{ 'is-invalid': v$.organisation.name.$error }"
        :placeholder="$t('po_cg_tx_name')" ref="name" v-model="organisation.name">
      <div class="invalid-feedback">{{ v$.organisation.name.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">{{ $t('po_cg_lb_email') }}*</label>
      <input id="email" name="email" type="text" class="form-control" :class="{ 'is-invalid': v$.organisation.email.$error }"
        :placeholder="$t('po_cg_tx_email')" ref="email" v-model="organisation.email">
        <div class="invalid-feedback">{{ v$.organisation.email.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="vatin" class="form-label">{{ $t('po_cg_lb_vatin') }}*</label>
      <input id="vatin" name="vatin" type="text" class="form-control" :class="{ 'is-invalid': v$.organisation.vatin.$error }"
        :placeholder="$t('po_cg_lb_vatin')" ref="vatin" v-model="organisation.vatin">
      <div class="invalid-feedback">{{ v$.organisation.vatin.$errors[0]?.$message }}</div>
    </div>
    <addressForm v-model="organisation.invoiceAddress" :textPostFix="$t('po_cg_lb_address_postfix')"></addressForm>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/config/i18n-validators'
import addressForm from '@/components/common/AddressForm.vue'

export default {
  props: {
    modelValue: Object
  },
  components: {
    addressForm
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      organisation: {
        name: { required },
        email: { required, email },
        vatin: { required }
      }
    }
  },
  computed: {
    organisation: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
