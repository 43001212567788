<template>
  <div class="row justify-content-center">
    <div class="col-10 col-lg-6">
      <div class="card">
        <div class="card-body">
          <div v-if="loginResponseStatus == 403" class="alert alert-danger" role="alert">
            {{ $t('po_re_tx_login_forbidden') }}
          </div>
          <div v-else-if="loginResponseStatus && loginResponseStatus != 200" class="alert alert-danger" role="alert">
            {{ $t('po_re_tx_login_error') }}
          </div>
          <form id="login" @submit.prevent="login">
            <div class="mb-3">
              <input id="email" name="email" type="email" class="form-control" :class="{ 'is-invalid': v$.email.$error }" :placeholder="$t('po_re_lb_email')" v-model="email">
              <div class="invalid-feedback">{{ v$.email.$errors[0]?.$message }}</div>
            </div>
            <div class="mb-3">
              <input id="password" name="password" type="password" class="form-control" :class="{ 'is-invalid': v$.password.$error }" :placeholder="$t('po_re_lb_password')" v-model="password">
              <div class="invalid-feedback">{{ v$.password.$errors[0]?.$message }}</div>
            </div>
            <div class="row">
              <div class="col-6 col-md-4 d-grid">
                <router-link :to="{name: 'register'}" class="btn btn-secondary">{{ $t('po_re_bt_reg') }}</router-link>
              </div>
              <div class="col-6 col-md-8 d-grid">
                <button type="submit" class="btn btn-primary">{{ $t('po_re_bt_log') }}</button>
              </div>
            </div>
            <div class="row">
              <div class="offset-6 col-6 offset-md-4 col-md-8 text-center">
                <small><router-link :to="{name: 'forgotPassword'}">{{ $t('po_re_bt_passw_forgot') }}</router-link></small>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/config/i18n-validators'

export default {
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      email: { required, email },
      password: { required }
    }
  },
  data: function () {
    return {
      loginResponseStatus: 0,
      email: '',
      password: ''
    }
  },
  methods: {
    async login () {
      this.resetErrors()
      if (await this.v$.$validate()) {
        const redirect = this.$auth.redirect()
        this.$auth.login({
          data: { email: this.email, password: this.password },
          staySignedIn: false,
          redirect: {
            name: redirect ? redirect.from.name : 'home'
          }
        }).then(() => {
          this.$store.dispatch('setUser', this.$auth.user())
        }, (e) => {
          if (e.response) {
            this.loginResponseStatus = e.response.status
          } else {
            this.loginResponseStatus = 999
          }
        })
      }
    },
    resetErrors () {
      this.loginResponseStatus = 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
