<template>
  <select v-model="selectedLocale" class="form-select" >
    <option v-for="(locale, i) in this.$i18n.availableLocales" :key="`lang-${i}`" :value="locale">
      {{ $t(`po_ge_me_lang_${locale}`) }}
    </option>
  </select>
</template>

<script>
export default {
  computed: {
    selectedLocale: {
      get () {
        return this.$i18n.locale
      },
      set (value) {
        this.$router.push({ params: { locale: value } })
        this.$i18n.locale = value
      }
    }
  }
}
</script>
