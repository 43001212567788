<template>
  <div class="row justify-content-center">
    <div class="col-10 col-lg-6">
      <div>
        <h4 class="text-center">{{ $t('po_re_he_reg') }}</h4>

        <form id="Login" @submit.prevent="register">
          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('po_re_he_organisation') }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_re_sh_organisation') }}</h6>
              <organisation-form v-model="organisationData"></organisation-form>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('po_re_he_organisation_admin') }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_re_sh_organisation_admin') }}</h6>
              <user-form v-model="user"></user-form>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('po_re_he_location') }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_re_sh_location') }}</h6>
              <location-form v-model=locationData></location-form>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('po_re_he_location_details') }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_re_sh_location_details') }}</h6>
              <location-details-form v-model=locationDetailsData></location-details-form>
            </div>
          </div>

          <div class="card mb-4">
            <div class="card-body">
              <h5 class="card-title">{{ $t('po_re_he_location_opening_horus') }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">{{ $t('po_re_sh_location_opening_horus') }}</h6>
              <location-opening-hours-form v-model=locationOpeningHoursData></location-opening-hours-form>
            </div>
          </div>

          <div class="mb-4">
            <div class="form-check">
              <input id="acceptLegal" name="acceptLegal" type="checkbox" v-model="acceptLegal" class="form-check-input" required>
              <label class="form-check-label" for="acceptLegal">
                <i18n-t keypath="po_cg_lb_legal" tag="label" for="acceptLegal" class="form-check-label">
                  <template v-slot:terms>
                    <a href="https://www.tapget.com/legal">{{ $t('po_cg_tx_terms') }}</a>
                  </template>
                  <template v-slot:privacy>
                    <a href="https://www.tapget.com/privacy-policy">{{ $t('po_cg_tx_privacy') }}</a>
                  </template>
                </i18n-t>*
              </label>
            </div>
          </div>

          <div class="d-grid mb-4">
            <button type="submit" class="col btn btn-primary mt-3" :disabled="!acceptLegal">
              {{ $t('po_re_bt_reg') }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import userForm from '@/components/user/UserForm.vue'
import organisationForm from '@/components/organisation/OrganisationForm.vue'
import locationForm from '@/components/location/LocationForm.vue'
import locationDetailsForm from '@/components/location/LocationDetailsForm.vue'
import locationOpeningHoursForm from '@/components/location/LocationOpeningHoursForm.vue'

export default {
  components: {
    userForm,
    organisationForm,
    locationForm,
    locationDetailsForm,
    locationOpeningHoursForm
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  data: function () {
    return {
      user: {},
      organisationData: {
        name: '',
        email: '',
        invoiceAddress: {
          country: 'at'
        }
      },
      locationData: {
        address: {
          country: 'at'
        }
      },
      locationDetailsData: {},
      locationOpeningHoursData: {
        MON: {
          from: null,
          to: null
        },
        TUE: {
          from: null,
          to: null
        },
        WED: {
          from: null,
          to: null
        },
        THU: {
          from: null,
          to: null
        },
        FRI: {
          from: null,
          to: null
        },
        SAT: {
          from: null,
          to: null
        },
        SUN: {
          from: null,
          to: null
        }
      },
      acceptLegal: false
    }
  },
  methods: {
    async register () {
      if (await this.v$.$validate()) {
        this.user.birthdate = '1990-01-01'
        const data = {
          firstname: this.user.firstname,
          lastname: this.user.lastname,
          email: this.user.email,
          password: this.user.password,
          birthdate: this.user.birthdate,
          data: {
            organisation: this.organisationData,
            location: this.locationData,
            locationDetails: this.locationDetailsData,
            locationOpeningHours: []
          }
        }
        for (const weekday in this.locationOpeningHoursData) {
          const day = this.locationOpeningHoursData[weekday]
          if (day.start != null && day.end != null) {
            data.data.locationOpeningHours.push({
              weekday: weekday,
              start: day.start,
              end: day.end
            })
          }
        }
        this.axios.post('/register/free', data).then((res) => {
          this.$router.push({ name: 'registered' })
        }).catch((err) => {
          console.log(err)
          this.registerError = true
          this.registerDisabled = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
