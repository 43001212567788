<template>
  <div>
    <div class="btn-group" role="group" :aria-label="$t('po_le_lb_qr_codes')">
      <div class="input-group-prepend">
          <div class="input-group-text" id="btnGroupAddon">{{ $t('po_le_lb_qr_codes') }}</div>
      </div>
      <button type="button" class="btn btn-secondary" @click="downloadQrCode('svg')">SVG</button>
      <button type="button" class="btn btn-secondary" @click="downloadQrCode('pdf')">PDF</button>
      <button type="button" class="btn btn-secondary" @click="downloadQrCode('png')">PNG</button>
      <button type="button" class="btn btn-secondary" @click="downloadQrCode('embedded')">PDF A4</button>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  props: {
    restaurant: Object
  },
  data: function () {
    return {
      responseStatus: null,
      mainText: this.restaurant.texts.find((text) => text.language === this.restaurant.language)
    }
  },
  methods: {
    downloadQrCode: function (format) {
      this.axios({
        method: 'get',
        url: `/vendor/restaurants/${this.restaurant.id}/qrcodes?format=${format}`,
        responseType: 'blob'
      }).then((res) => {
        this.responseStatus = res.status
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(res.data)
        link.download = `${DateTime.utc().toISODate()}-${this.mainText.name}-${format}.zip`
        if (typeof link.download === 'undefined') {
          link.setAttribute('target', '_blank')
        }
        link.classList.add('d-none')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        window.URL.revokeObjectURL(link.href)
      }).catch((e) => {
        this.responseStatus = e.response.status
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
