<template>
  <table class="table">
    <thead>
      <tr class="d-flex">
        <th scope="col" class="col-10">{{ $t('po_le_hl_email') }}</th>
        <th scope="col" class="col-1 text-center">{{ $t('po_le_hl_waiter') }}</th>
        <th scope="col" class="col-1 text-center">{{ $t('po_le_hl_manager') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="u in sortedUsers" :key="u.uuid" class="d-flex">
        <td class="col-10">{{u.email}} <span v-if="u.uuid === $auth.user().uuid">(Sie)</span></td>
        <td class="col-1 text-center">
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" :id="`${u.uuid}-waiter`" :disabled="loading[`${u.uuid}`]" :checked="hasRole(u, 'waiter')" @change="switchRole(u, 'waiter')">
            <label class="custom-control-label" :for="`${u.uuid}-waiter`"></label>
          </div>
        </td>

        <td class="col-1 text-center">
          <div class="form-check form-switch">
            <input type="checkbox" class="form-check-input" :id="`${u.uuid}-admin`" :disabled="u.uuid === $auth.user().uuid || loading[`${u.uuid}`]" :checked="hasRole(u, 'admin')" @change="switchRole(u, 'admin')">
            <label class="custom-control-label" :for="`${u.uuid}-admin`"></label>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    restaurant: Object
  },
  data: function () {
    return {
      responseStatus: 0,
      loading: {}
    }
  },
  computed: {
    sortedUsers: function () {
      const users = (this.$store.state.organisation.organisationUsers || []).slice()
      users.forEach(user => {
        user.userRoles = user.userRoles.filter(userRole => userRole.restaurantId === this.restaurant.id)
      })

      return users.sort((a, b) => a.email.localeCompare(b.email))
    }
  },
  methods: {
    hasRole: function (user, role) {
      return user.userRoles.some((userRole) => userRole.role === role)
    },
    addRole: function (user, role) {
      this.loading[user.uuid] = true

      const roles = user.userRoles.map(userRole => ({ role: userRole.role }))
      roles.push({ role: role })
      this.axios.put(`/vendor/restaurants/${this.restaurant.id}/users/${user.id}`, roles).then((res) => {
        this.responseStatus = res.status
        this.$store.dispatch('updateOrganisation')
      }).catch((e) => {
        this.responseStatus = e.response.status
      }).finally(() => {
        this.loading[user.uuid] = false
      })
    },
    removeRole: function (user, role) {
      this.loading[user.uuid] = true

      const roles = user.userRoles.filter((userRole) => userRole.role !== role).map(userRole => ({ role: userRole.role }))
      this.axios.put(`/vendor/restaurants/${this.restaurant.id}/users/${user.id}`, roles).then((res) => {
        this.responseStatus = res.status
        this.$store.dispatch('updateOrganisation')
      }).catch((e) => {
        this.responseStatus = e.response.status
      }).finally(() => {
        this.loading[user.uuid] = false
      })
    },
    switchRole: function (user, role) {
      if (event.target.checked) {
        this.addRole(user, role)
      } else {
        this.removeRole(user, role)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
