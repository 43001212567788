<template>
  <div class="modal" tabindex="-1" role="dialog" :id="id">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <p>{{text}}</p>
          <p v-if="subText" class="text-muted">{{subText}}</p>
        </div>
        <div class="modal-footer">
          <button @click="$emit('confirm')" type="button" class="btn btn-danger" data-bs-dismiss="modal">{{textConfirm}}</button>
          <button @click="$emit('close')" type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{textClose}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    text: String,
    subText: String,
    textConfirm: String,
    textClose: String
  }
}
</script>
