<template>
  <div>
    <div class="home container-fluid">
      <div class="row">
        <div class="col-lg">
        </div>
        <div class="col-lg-6 text-center">
          <h4>{{ $t('po_hm_tx_excl-offer') }}</h4>
          <p>{{ $t('po_hm_tx_30days') }}</p>
        </div>
        <div class="col-lg">
        </div>
      </div>
      <div class="mt-5">
        <categories></categories>
      </div>
    </div>
  </div>
</template>

<script>
import categories from '@/components/Categories.vue'

export default {
  components: {
    categories
  }
}
</script>
