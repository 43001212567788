<template>
  <div v-if="restaurant" class="container-fluid">
    <ul class="list-group mb-3">
      <li class="list-group-item">
        <restaurant-overview :restaurant="restaurant" v-if="restaurant"></restaurant-overview>
      </li>
      <li v-if="isManager" class="list-group-item">
        <h5>{{ $t('po_le_he_tables') }}</h5>
        <restaurant-tables :restaurant="restaurant"></restaurant-tables>
      </li>
      <li v-if="isManager" class="list-group-item">
        <h5>{{ $t('po_le_he_users')}}</h5>
        <restaurant-users :restaurant="restaurant"></restaurant-users>
      </li>
    </ul>
    <ul class="list-group">
      <li v-if="isManager" class="list-group-item">
        <h5>{{ $t('po_le_hl_contracts') }}</h5>
        <location-contract :location="restaurant"></location-contract>
      </li>
    </ul>
  </div>
</template>

<script>
import restaurantOverview from '@/components/RestaurantOverview.vue'
import restaurantTables from '@/components/restaurant/RestaurantTables.vue'
import restaurantUsers from '@/components/RestaurantUsers.vue'
import locationContract from '@/components/restaurant/LocationContract.vue'

export default {
  props: {
    id: String
  },
  components: {
    restaurantOverview,
    restaurantTables,
    restaurantUsers,
    locationContract
  },
  data: function () {
    return {
      restaurant: null,
      isWaiter: this.$auth.check({ [this.id]: ['waiter'] }),
      isManager: this.$auth.check({ [this.id]: ['admin'] })
    }
  },
  created: function () {
    this.axios.get(`/vendor/restaurants/${this.id}`)
      .then((res) => {
        this.restaurant = res.data.data
      })
  }

}
</script>
