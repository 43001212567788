<template>
  <div>
    <div class="mb-3">
      <label for="firstname" class="form-label">{{ $t('po_re_lb_firstname') }}*</label>
      <input id="firstname" name="firstname" type="text" class="form-control" :class="{ 'is-invalid': v$.user.firstname.$error }"
        :placeholder="$t('po_re_lb_firstname')" ref="firstname" v-model="user.firstname">
      <div class="invalid-feedback">{{ v$.user.firstname.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <label for="lastname" class="form-label">{{ $t('po_re_lb_lastname') }}*</label>
      <input id="lastname" name="lastname" type="text" class="form-control" :class="{ 'is-invalid': v$.user.lastname.$error }"
        :placeholder="$t('po_re_lb_lastname')" ref="lastname" v-model="user.lastname">
      <div class="invalid-feedback">{{ v$.user.lastname.$errors[0]?.$message }}</div>
    </div>
    <div v-if="showEmail" class="mb-3">
      <label for="email" class="form-label">{{ $t('po_re_lb_email') }}*</label>
      <input id="email" name="email" type="text" class="form-control" :class="{ 'is-invalid': v$.user.email.$error }"
        :placeholder="$t('po_re_lb_email')" ref="email" v-model="user.email">
        <div class="invalid-feedback">{{ v$.user.email.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-2">
      <label for="password" class="form-label">{{ $t('po_re_lb_password') }}*</label>
      <input id="password" name="password" type="password" class="form-control" :class="{ 'is-invalid': v$.user.password.$error }"
        :placeholder="$t('po_re_lb_password')" ref="password" v-model="user.password">
      <div class="invalid-feedback">{{ v$.user.password.$errors[0]?.$message }}</div>
    </div>
    <div class="mb-3">
      <input id="repeatPassword" name="repeatPassword" type="password" class="form-control" :class="{ 'is-invalid': v$.repeatPassword.$error }"
        :placeholder="$t('po_re_lb_password_repeat')"  ref="repeatPassword" v-model="repeatPassword">
      <div class="invalid-feedback">{{ v$.repeatPassword.$errors[0]?.$message }}</div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength, sameAs } from '@/config/i18n-validators'

export default {
  props: {
    modelValue: Object,
    showEmail: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      user: {
        firstname: { required },
        lastname: { required },
        email: { required, email },
        password: { required, minLength: minLength(8) }
      },
      repeatPassword: { required, sameAs: sameAs(this.user.password) }
    }
  },
  data: function () {
    return {
      disabledDates: {
        from: new Date()
      },
      repeatPassword: ''
    }
  },
  computed: {
    user: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    openDate: function () {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 20)
      return date
    }
  }
}
</script>
