<template>
  <div class="container">
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-3" v-if="waiting">
        <p class="lead">Ihr Einladungs-Link wird überprüft.</p>
      </div>
      <div class="container-fluid py-3" v-if="!waiting && !valid">
        <h1>Oh Nein!</h1>
        <p class="lead">Ihr Einladungs-Link ist leider ungültig.</p>
        <p>Der Einladungs-Link ist nur eine begrenzte Zeit gültig. Kontaktieren Sie bitte den Manager der Organisation und fordern Sie einen neuen Einladungs-Link an.</p>
      </div>
      <div class="container-fluid py-3" v-if="updated">
        <p class="lead">Ihre Einladung wurde bestätigt.</p>
        <p>Sie sind jetzt der Organisation beigetreten. Der Manager der Organisation kann Ihnen zusätzliche Berechtigungen für bestimmte Restaurants erteilen.</p>
        <p v-if="!$auth.check()" class="lead">
          <router-link :to="{ name: 'login' }" class="btn btn-primary btn-lg">Zum Login</router-link>
        </p>
      </div>
    </div>
    <div class="row justify-content-center" v-if="valid && !updated && !hasUser">
      <div class="col-10 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="alert alert-danger" role="alert" v-if="error">
              Leider ist beim Bestätigen der Einladung ein Fehler aufgetreten. Bitte versuchen Sie es erneut.
            </div>
            <form id="Login" @submit.prevent="confirmInvitation">
              <user-form v-model="userData" :showEmail="false"></user-form>
              <div class="container">
                <div class="row">
                  <div class="col-12 p-1">
                    <button type="submit" class="btn btn-primary btn-block">Einladung bestätigen</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userForm from '@/components/user/UserForm.vue'

export default {
  components: {
    userForm
  },
  props: ['token'],
  data: function () {
    return {
      waiting: true,
      valid: false,
      updated: false,
      user: null,
      userData: {},
      error: false
    }
  },
  created: function () {
    this.axios.get(`/auth/organisation/invite/${this.token}`)
      .then((res) => {
        this.waiting = false
        this.valid = true
        this.user = res.data
      }).catch(() => {
        this.waiting = false
        this.valid = false
      })
  },
  computed: {
    hasUser: function () {
      return this.user && this.user.email
    }
  },
  watch: {
    user: function () {
      if (this.hasUser) {
        this.confirmInvitation()
      }
    }
  },
  methods: {
    confirmInvitation: function () {
      this.error = false
      const params = this.hasUser ? null : this.userData

      this.axios.post(`/auth/organisation/invite/${this.token}`, params).then((res) => {
        this.updated = true
      }).catch(() => {
        this.error = true
      })
    }
  }
}
</script>
