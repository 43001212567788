<template>
  <div class="row justify-content-center">
    <div class="card col-11 col-md-8 my-2">
      <div class="card-body">
        <h5 class="card-title">{{ $t('po_lc_he_loc-create') }}</h5>
        <div v-if="createError" class="alert alert-danger" role="alert">
          {{ $t('po_lc_tx_error_create') }}
        </div>
        <form id="Login" @submit.prevent="create">
          <locationForm v-model=locationData></locationForm>
          <button type="submit" class="col btn btn-primary mt-3">
            {{ $t('po_lc_bt_create') }}
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import locationForm from '@/components/location/LocationForm.vue'

export default {
  components: {
    locationForm
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  data: function () {
    return {
      createError: false,
      locationData: {
        address: {
          country: 'at'
        }
      }
    }
  },
  computed: {
    organisation: function () {
      return this.$store.state.organisation.organisation || {}
    },
    organisationUsers: function () {
      return this.$store.state.organisation.organisationUsers || []
    }
  },
  methods: {
    async create () {
      if (await this.v$.$validate()) {
        const submitData = this.locationData

        submitData.language = 'de'
        submitData.timezone = 'Europe/Vienna'
        submitData.lat = '48.2207779'
        submitData.lon = '16.3098493'
        submitData.currency = 'EUR'
        submitData.active = false
        submitData.visible = false
        submitData.texts = [
          {
            language: 'de',
            name: this.locationData.name
          }
        ]

        try {
          await this.axios.post('/vendor/restaurants/', submitData)
          await this.$auth.fetch()
          await this.$store.dispatch('updateOrganisation')
          this.$router.push({ name: 'locations' })
        } catch (e) {
          this.createError = true
        }
      }
    }
  }
}
</script>
