<template>
  <div class="justify-content-center">
    <div v-for="productUuid in productUuids" :key="productUuid" class="d-flex flex-wrap justify-content-center p2">
      <product :productUuid="productUuid"></product>
    </div>
    <div class="text-muted small text-center">
      {{ $t('po_hm_tx_vat') }}
    </div>
  </div>
</template>

<script>
import product from '@/components/Product.vue'

export default {
  components: {
    product
  },
  data: function () {
    return {
      productUuids: ['3182eace-7709-416f-b8ac-29b105d1db44']
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
