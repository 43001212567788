<template>
  <div v-if="contract">
    <div class="d-flex w-100 justify-content-between">
      <h5 class="mb-1">{{ $t('po_ge_tx_product_name') }}</h5>
      <div class="text-right">
        <div>{{ contract.unitPrice * contract.quantity }} {{ contract.currency }} {{ $t(`po_ge_tx_timeunit_${contract.timeUnit}`) }}</div>
        <div>{{ contract.locations.length }} / {{ $tc('po_ge_tx_unit_locations', contract.quantity) }}</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contract: Object
  }
}
</script>
