<template>
  <div>
    <div class="row mb-3" v-for="(value, key) in locationOpeningHours" :key="key">
      <div class="col-md-4 col-form-label">
         {{$t('po_lc_lb_days_' + key.toLowerCase())}}
      </div>
      <div class="col-md-4">
        <label :for="key + '-start'" class="form-label">{{ $t('po_lc_lb_start') }}</label>
        <input :id="key + '-start'" :name="key + '-start'" type="time" class="form-control" :class="{ 'is-invalid': v$.locationOpeningHours.start.$error }"
               :ref="key + '-start'" v-model="value.from">
        <div class="invalid-feedback">{{ v$.locationOpeningHours.start.$errors[0]?.$message }}</div>
      </div>
      <div class="col-md-4">
        <label :for="key + '-end'" class="form-label">{{ $t('po_lc_lb_end') }}</label>
        <input :id="key + '-end'" :name="key + '-end'" type="time" class="form-control" :class="{ 'is-invalid': v$.locationOpeningHours.end.$error }"
               :ref="key + '-end'" v-model="value.to">
        <div class="invalid-feedback">{{ v$.locationOpeningHours.end.$errors[0]?.$message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'

export default {
  props: {
    modelValue: Object
  },
  components: {
  },
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      locationOpeningHours: {
        start: { },
        end: { }
      }
    }
  },
  computed: {
    locationOpeningHours: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    }
  }
}
</script>
