<template>
  <div class="container-fluid">
    <div v-if="resetResponseStatus == 200" class="container">
      <div class="p-5 mb-4 bg-light rounded-3">
        <div class="container-fluid py-3">
          <h1>{{ $t('po_fp_hl_success') }}</h1>
          <p class="lead">{{ $t('po_fp_tx_success') }}</p>
        </div>
      </div>
    </div>

    <div v-else class="row justify-content-center">
      <div class="col-10 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="card-text">
            <div v-if="resetResponseStatus == 404" class="alert alert-danger" role="alert">
              {{ $t('po_fp_tx_not_found') }}
            </div>
            <div v-else-if="resetResponseStatus && resetResponseStatus != 200" class="alert alert-danger" role="alert">
              {{ $t('po_fp_tx_error') }}
            </div>
            <form id="Login" @submit.prevent="requestPasswordReset">
              <div class="mb-3">
                <input id="email" name="email" type="email" class="form-control" :class="{ 'is-invalid': v$.email.$error }" :placeholder="$t('po_fp_bt_email')" v-model="email">
                <div class="invalid-feedback">{{ v$.email.$errors[0]?.$message }}</div>
              </div>
              <div class="d-grid">
                <button type="submit" class="btn btn-primary" :disabled="requestDisabled">{{ $t('po_fp_bt_link') }}</button>
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email } from '@/config/i18n-validators'

export default {
  setup () {
    return {
      v$: useVuelidate({ $autoDirty: true })
    }
  },
  validations () {
    return {
      email: { required, email }
    }
  },
  data: function () {
    return {
      resetResponseStatus: 0,
      notFound: false,
      requestDisabled: false,
      email: ''
    }
  },
  methods: {
    async requestPasswordReset () {
      this.resetErrors()
      this.requestDisabled = true
      if (await this.v$.$validate()) {
        const params = {
          email: this.email
        }

        this.axios.post('/auth/password/reset', params).then((response) => {
          this.resetResponseStatus = response.status
        }).catch((e) => {
          if (e.response) {
            this.resetResponseStatus = e.response.status
          } else {
            this.resetResponseStatus = 999
          }
          this.requestDisabled = false
        })
      } else {
        this.requestDisabled = false
      }
    },
    resetErrors: function () {
      this.resetResponseStatus = 0
    }
  }
}
</script>
